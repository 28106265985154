'use client';

import { useEffect, useRef, useState } from 'react';
import { PrismicNextLink } from '@prismicio/next';
import clsx from 'clsx';
import { GroupField } from '@prismicio/client';
import Link from '@/components/atoms/Link';
import HamburgerOpen from '/public/assets/svg/menu.svg';
import ChevronDown from '/public/assets/svg/chevron-down.svg';
import HamburgerClosed from '/public/assets/svg/close.svg';
import { ConfigDocumentDataButtonItemsItem, HeaderItemDocument, Simplify } from '@/prismicio-types';
import LinkButton from '@/components/atoms/LinkButton';
import Logo from '/public/assets/svg/logo.svg';
import useOutsideClickHandler from '@/hooks/useOutsideAlerter';
import useScrollLock from '@/hooks/useScrollClock';
import useRWD from '@/hooks/useRWD';
import useScrollDetect from '@/hooks/useScrollDetect';
import { Lang } from '@/types/locales';
import DropdownItem from '../DropdownItem';
import { LanguageSwitcher } from '../LanguageSwitcher';
type Props = {
  headerItems: HeaderItemDocument<string>[];
  buttonItems: GroupField<Simplify<ConfigDocumentDataButtonItemsItem>>;
  lang: Lang;
};
const Menu = ({
  headerItems,
  buttonItems,
  lang
}: Props) => {
  const ref = useRef(null);
  const [openTab, setOpenTab] = useState(-1);
  const [prevTab, setPrevTab] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const {
    isDesktop
  } = useRWD();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideMenuOnClick = () => {
    setPrevTab(openTab > -1 ? openTab : 0);
    setOpenTab(-1);
    setShowMenu(false);
  };
  const handleMenuHover = (index: number) => {
    if (isDesktop) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setPrevTab(openTab > -1 ? openTab : 0);
      setOpenTab(index);
    }
  };
  const handleMenuLeave = () => {
    if (isDesktop) {
      // We set a delay to close the menu, allowing the user time to move to the submenu
      timeoutRef.current = setTimeout(() => {
        setPrevTab(openTab);
        setOpenTab(-1);
      }, 200);
    }
  };
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  useOutsideClickHandler(ref, () => {
    setPrevTab(openTab > -1 ? openTab : 0);
    setOpenTab(-1);
  });
  useScrollLock(showMenu && !isDesktop || openTab !== -1 && isDesktop);
  const movedScroll = useScrollDetect();
  return <div ref={ref} className={clsx('relative z-10 flex h-full flex-col lg:flex-row lg:justify-between', 'lg:h-auto lg:overflow-y-visible', showMenu && 'h-screen overflow-y-auto bg-white lg:bg-transparent')} data-sentry-component="Menu" data-sentry-source-file="index.tsx">
      <div className={clsx('pointer-events-none fixed left-0 top-0 h-[88px] w-full -translate-y-full bg-white transition-transform', movedScroll && 'translate-y-0 bg-transparent')} />
      <div className="flex flex-col lg:flex-row">
        <div className="sticky top-0 z-20 flex justify-between bg-white md:bg-transparent lg:static lg:pr-10 lg:pt-3">
          <Link onClick={() => hideMenuOnClick()} href="/" className="flex items-center px-7 py-4 lg:px-0" aria-label="link to home page" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <Logo className="h-8 w-40" data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
          </Link>
          <button className="mb-4 mr-6 mt-6 flex h-11 w-11 items-center justify-center rounded-full border border-gray-200 lg:hidden" onClick={() => setShowMenu(prev => !prev)} aria-label="menu button">
            {showMenu ? <HamburgerClosed /> : <HamburgerOpen />}
          </button>
        </div>
        <div className={clsx('flex w-full items-center justify-between', showMenu ? 'h-auto' : 'h-0 lg:h-auto')}>
          <div className={clsx('z-10 mb-6 flex w-full flex-col items-center pl-0 lg:mb-0 lg:h-full lg:flex-row lg:transition-opacity lg:duration-200', showMenu ? 'opacity-100' : 'hidden opacity-0 lg:flex lg:opacity-100')}>
            {headerItems.map(({
            data: item
          }, index) => item?.link?.link_type === 'Any' ? <div style={{
            order: index
          }} role="button" className={clsx('flex h-full w-full items-center justify-between gap-1 lg:justify-start', 'whitespace-nowrap text-body-6 font-semibold text-gray-600 lg:text-menu lg:font-medium', 'z-10 px-7 py-3 lg:px-3.5 lg:pt-6', 'hover:text-opacity-50')} key={index} onClick={() => {
            if (!isDesktop) {
              setPrevTab(openTab);
              setOpenTab(prev => index == prev ? -1 : index);
            }
          }} onMouseEnter={() => handleMenuHover(index)} onMouseLeave={handleMenuLeave}>
                  {item?.title}
                  <ChevronDown className={clsx('h-4 w-4 scale-150 transition-transform lg:scale-100', {
              ['rotate-180']: index == openTab
            })} />
                </div> : <PrismicNextLink onClick={() => hideMenuOnClick()} style={{
            order: index
          }} className="z-10 flex h-full w-full items-center whitespace-nowrap px-7 py-3 text-body-6 font-semibold text-gray-600 hover:text-opacity-50 lg:justify-center lg:px-3.5 lg:pt-6 lg:text-menu lg:font-medium" key={index} field={item.link} lang={lang}>
                  {item?.title}
                </PrismicNextLink>)}
            <DropdownItem hideMenu={hideMenuOnClick} prevTab={prevTab} openTab={openTab} item={headerItems[openTab > -1 ? openTab : prevTab]?.data} onMouseEnter={() => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = null;
            }
          }} onMouseLeave={handleMenuLeave} data-sentry-element="DropdownItem" data-sentry-source-file="index.tsx" />
          </div>
        </div>
      </div>
      <div className={clsx(showMenu ? 'flex' : 'hidden lg:flex', 'z-10 flex-col-reverse items-center gap-3 px-3 pb-4 lg:w-full lg:flex-row lg:justify-end lg:pb-0 lg:pt-3')}>
        <LanguageSwitcher {...{
        lang
      }} data-sentry-element="LanguageSwitcher" data-sentry-source-file="index.tsx" />
        <div className="flex w-full auto-cols-fr grid-flow-col flex-col gap-3 lg:grid lg:w-auto xl:auto-cols-[minmax(10.375rem,_1fr)]">
          {buttonItems.map(buttonItem => {
          return <LinkButton key={buttonItem.text} className="w-full lg:max-w-[10.375rem]" variant={buttonItem.variant} field={buttonItem.link} onClick={() => {
            hideMenuOnClick();
          }}>
                {buttonItem.text}
              </LinkButton>;
        })}
        </div>
      </div>
    </div>;
};
export default Menu;