'use client';

import React, { PropsWithChildren, useState } from 'react';
import { createInstance } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import initTranslations from './init';
import { TranslationProviderProps } from './types';
const TranslationProvider = React.memo<PropsWithChildren<TranslationProviderProps>>(({
  children,
  locale,
  resources
}) => {
  const [i18n] = useState(() => {
    const instance = createInstance();
    initTranslations(locale, instance, resources);
    return instance;
  });
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}, (prevProps, newProps) => (Object.keys(prevProps) as Array<keyof TranslationProviderProps>).reduce((areEqual, key) => {
  if (!areEqual) {
    return false;
  }

  // The resources will never change, so we can skip the comparison
  // Otherwise this component will unmount and cause issues
  if (key === 'resources') {
    return areEqual;
  }
  return areEqual && prevProps[key] === newProps[key];
}, true));
TranslationProvider.displayName = 'TranslationProvider';
export default TranslationProvider;