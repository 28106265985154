'use client';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import Flag from '@/components/atoms/Flag';
import { ChevronDownIcon } from '@/public/assets/svg';
import { LOCALES_MAP } from '@/consts/locales';
import { useAlternateLanguages } from '@/contexts/AlternateLanguagesContext/hooks';
import { Lang } from '@/types/locales';
import { LanguageSwitcherProps } from './types';
export const LanguageSwitcher = ({
  lang,
  position = 'bottom'
}: LanguageSwitcherProps) => {
  const {
    items
  } = useAlternateLanguages();
  return <Menu as="div" data-sentry-element="Menu" data-sentry-component="LanguageSwitcher" data-sentry-source-file="index.tsx">
      <MenuButton className="flex w-full items-center justify-between rounded-full border border-gray-100 bg-white p-3 lg:w-auto lg:justify-center" aria-label="flag button" data-sentry-element="MenuButton" data-sentry-source-file="index.tsx">
        <div className="flex flex-row items-center">
          <Flag locale={lang} data-sentry-element="Flag" data-sentry-source-file="index.tsx" />
          <span className="ml-2 font-medium text-gray-600 lg:hidden">{LOCALES_MAP[lang]}</span>
        </div>
        <ChevronDownIcon className="block h-4 w-4 lg:hidden" data-sentry-element="ChevronDownIcon" data-sentry-source-file="index.tsx" />
      </MenuButton>
      <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0" enterTo="opacity-100" leave="transition ease-in duration-150" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="Transition" data-sentry-source-file="index.tsx">
        <MenuItems modal={false} anchor={position === 'bottom' ? 'bottom end' : 'top end'} className={clsx('z-50 origin-top-right rounded-xl border border-gray-100 bg-white p-4 text-body-6 text-gray-600 focus:outline-none', {
        'mt-1': position === 'bottom',
        'mb-1': position === 'top'
      })} data-sentry-element="MenuItems" data-sentry-source-file="index.tsx">
          {items.map(locale => <MenuItem key={locale.lang}>
              <a className="group flex w-full flex-row items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-gray-50" data-lang={locale.lang} href={locale.path}>
                <Flag locale={locale.lang as Lang} className="flex-shrink-0" />
                <p>{LOCALES_MAP[locale.lang]}</p>
              </a>
            </MenuItem>)}
        </MenuItems>
      </Transition>
    </Menu>;
};