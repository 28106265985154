'use client';

import React, { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';
import swrConfig from '@/setup/swr/config';
const SWRProvider = ({
  children
}: PropsWithChildren) => {
  return <SWRConfig value={swrConfig} data-sentry-element="SWRConfig" data-sentry-component="SWRProvider" data-sentry-source-file="SWRProvider.tsx">{children}</SWRConfig>;
};
export default SWRProvider;