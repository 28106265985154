'use client';

import React, { PropsWithChildren, useMemo, useState } from 'react';
import { AlternateLanguagesContext } from './consts';
import { AlternateLanguagesContextValue } from './types';
const AlternateLanguagesContextProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [items, setItems] = useState<AlternateLanguagesContextValue['items']>([]);
  return <AlternateLanguagesContext.Provider value={useMemo(() => ({
    items,
    setItems
  }), [items])} data-sentry-element="unknown" data-sentry-component="AlternateLanguagesContextProvider" data-sentry-source-file="index.tsx">
      {children}
    </AlternateLanguagesContext.Provider>;
};
export default AlternateLanguagesContextProvider;